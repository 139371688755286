/* .MuiTableHead-root, .topBar, .topnav {
    background: rgb(44, 44, 44);
}

.MuiTableHead-root th, .topnav {
    color: white !important;
    font-weight: bold !important;
}

.skucell {
    font-weight: bold !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: white !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: white !important;
} */

#root {
    background-color: rgb(46, 46, 46) !important;
}

.table {
    background-color: rgb(46, 46, 46) !important;
}

.skuFinderHolder {
    display: flex;
    align-items: center;
    width: 200px;
    padding: 10px;
    height: 50px;
    background: white;
}

.table-cell {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 2px 2px;
    overflow: hidden !important;
    color: white;
    width: 100px;
    font-weight: bold;
    background-color: rgb(32, 32, 32);
    padding: 10px;
}

.image-cell {
    color: white;
    padding: 15px 0;
}

.cell-image {
    margin: 0 10px;
    cursor: pointer;
    max-width: 100px;
    max-height: 100px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.123);
    border-radius: 2px;
}

.ref-holder {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.controls {
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
}

.table-header-cell {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    background-color: rgb(59, 59, 59);
    color: white;
    font-weight: bold;
    height: 100px;
}

.upper-row {
    position: fixed;
    bottom: 15px;
    left: 15px;
    z-index: 3;
}