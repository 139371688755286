.loading-container {
    background: rgb(32, 32, 32);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-grid {
    height: 100vh;
    color: white;
    font-weight: bold;
}